// import { mapActions } from 'vuex'
import { ServiceFactory } from '@/services/ServiceFactory'
const _adsBannerService = ServiceFactory.get('AdsBannerService')
const _barsService = ServiceFactory.get('BarsService')
const _citiesService = ServiceFactory.get('CitiesService')
export default {
  name: 'ZbAdsBanner',
  data: () => ({
    adBanner: {
      image: '',
      imageV2: ''
    },
    postData: {
      title: '',
      description: '',
      typeRedirect: null,
      order: null,
      externalLink: '',
      cityId: ''
    },
    // barData: {s
    //   bar: {
    //     images: {
    //       profile: null,
    //       banner: null,
    //       name: null,
    //       slogan: null
    //     }
    //   }
    // },
    // imageProfile: null,
    adsBanner: {
      ads: []
    },
    imageBanner: null,
    imageBannerV2: null,
    loading: false,
    loading2: false,
    dialog: false,
    enable: false,
    showError: false,
    submitOption: 'create',
    image: {
      width: '',
      height: ''
    },
    companies: [],
    gvExtLink: '',
    gvType: '',
    cities: [],
    cityBanner: null,
    bannerVersion: 'image',
    versions: [
      {
        text: 'Version 1',
        value: 'image'
      },
      {
        text: 'Version 2',
        value: 'image_v2'
      }
    ]
    // preview: {
    //   profile: null,
    //   banner: null
    // },
    // zbBarId: ''
  }),
  mounted () {
    // this.$refs.calendar.scrollToTime('08:00')
    this.initialize()
  },
  methods: {
    // ...mapActions('companiesModule', [
    //   'imageProfileUpload'
    // ]),
    async initialize () {
      // if (this.$route.params.id) {
      // this.zbBarId = this.$route.params.id
      // console.log(this.$route.params.id)
      this.loading2 = true
      try {
        this.companies = await _barsService.listBar()
        this.cities = await _citiesService.index('Bolivia')
        // console.log(this.cities.cities)
        var lpID = ''
        this.cities.cities.forEach(el => {
          if (el.name === 'La Paz') {
            lpID = el.zbCityId
          }
        })
        this.cityBanner = lpID
        console.log(lpID)
        this.adsBanner = await _adsBannerService.getAdsBannerCity(lpID)
        this.loading2 = false
        console.log(this.adsBanner)
      } catch (error) {
      // console.info(error)
        this.loading2 = false
        console.log(error)
      }
      // } else {
      // console.log('sin parametros')
      // this.loadingForm = false
      // }
    },
    async submitCreate () {
      try {
        this.postData.order = parseInt(this.postData.order)
        // console.log(this.postData)
        const responseBanner = await _adsBannerService.newAdBanner(this.postData)
        console.log(responseBanner.ads.id)
        const bannerId = responseBanner.ads.id
        this.submitImage(bannerId)
        this.$notify({
          type: 'success',
          text: 'Banner agregado correctamente'
        })
      } catch (error) {
        this.loading = false
        this.dialog = false
        this.$notify({
          type: 'error',
          text: 'Ocurrio un error al guardar el nuevo banner'
        })
      }
    },
    async submitEdit () {
      try {
        this.postData.order = parseInt(this.postData.order)
        await _adsBannerService.editAdBanner(this.postData)
        this.loading = false
        this.dialog = false
        this.initialize()
        this.$notify({
          type: 'success',
          text: 'Información de banner editada correctamente'
        })
      } catch (error) {
        this.loading = false
        this.dialog = false
        this.$notify({
          type: 'error',
          text: 'Ocurrio un error al editar los datos del banner'
        })
      }
    },
    async submitImage (bannerId) {
      try {
        if (this.imageBanner && this.imageBanner[0] && typeof this.imageBanner === 'object') {
          console.log('imagen v1', this.imageBanner, this.imageBanner[0])
          console.log(typeof this.imageBanner)
          const newImage = new FormData()
          newImage.append('image', this.imageBanner[0])
          await _adsBannerService.editImgBanner(bannerId, newImage, 'image')
          this.$notify({
            type: 'success',
            text: 'Imagen de banner version 1 editada correctamente'
          })
        }
        if (this.imageBannerV2 && this.imageBannerV2[0] && typeof this.imageBannerV2 === 'object') {
          console.log('imagen v2', this.imageBannerV2, this.imageBannerV2[0])
          const newImageV2 = new FormData()
          newImageV2.append('image', this.imageBannerV2[0])
          await _adsBannerService.editImgBanner(bannerId, newImageV2, 'image_v2')
          this.$notify({
            type: 'success',
            text: 'Imagen de banner version 2 editada correctamente'
          })
        }
        this.loading = false
        this.dialog = false
        this.initialize()
      } catch (error) {
        this.loading = false
        this.dialog = false
        this.$notify({
          type: 'error',
          text: 'Ocurrio un error al editar los datos del banner'
        })
      }
    },
    async submit () {
      this.loading = true
      console.log(this.postData)
      if (this.submitOption === 'create') {
        this.submitCreate()
      } else if (this.submitOption === 'edit') {
        this.submitEdit()
      } else {
        this.submitImage(this.postData.id)
      }
    },
    // async submitData () {
    //   this.loading = true
    //   const newImage = new FormData()
    //   var oneV = false
    //   var imageV1 = false
    //   var imageV2 = false
    //   if (this.imageBanner && this.imageBanner[0]) {
    //     newImage.append('image', this.imageBanner[0])
    //     oneV = true
    //     imageV1 = true
    //   }
    //   if (this.imageBannerV2 && this.imageBannerV2[0]) {
    //     newImage.append('imageV2', this.imageBannerV2[0])
    //     oneV = true
    //     imageV2 = true
    //   }
    //   if (oneV) {
    //     try {
    //       if (this.submitOption === 'create') {
    //         this.postData.order = parseInt(this.postData.order)
    //         // console.log(this.postData)
    //         await _adsBannerService.newAdBanner(this.postData, newImage)
    //         this.$notify({
    //           type: 'success',
    //           text: 'Imagen de banner agregado correctamente'
    //         })
    //       } else if (this.submitOption === 'edit') {
    //         this.postData.order = parseInt(this.postData.order)
    //         await _adsBannerService.editAdBanner(this.postData)
    //         this.$notify({
    //           type: 'success',
    //           text: 'Información de banner editada correctamente'
    //         })
    //       } else {
    //         if (imageV1) {
    //           await _adsBannerService.editImgBanner(this.postData.id, newImage, 'image')
    //           this.$notify({
    //             type: 'success',
    //             text: 'Imagen de banner version 1 editada correctamente'
    //           })
    //         }
    //         if (imageV2) {
    //           await _adsBannerService.editImgBanner(this.postData.id, newImage, 'image_v2')
    //           this.$notify({
    //             type: 'success',
    //             text: 'Imagen de banner version 2 editada correctamente'
    //           })
    //         }
    //       }
    //       this.loading = false
    //       this.dialog = false
    //       this.initialize()
    //     } catch (error) {
    //       this.loading = false
    //       // console.log(error)
    //       this.$notify({
    //         type: 'error',
    //         text: error.msg
    //       })
    //     }
    //   } else {
    //     this.loading = false
    //     this.$notify({
    //       type: 'error',
    //       text: 'Debe seleccionar al menos una imagen'
    //     })
    //   }
    // },
    async editBanner (item) {
      // console.log(item)
      this.submitOption = 'edit'
      this.dialog = true
      this.postData.id = item.id
      this.postData.title = item.title
      this.postData.description = item.description
      this.postData.externalLink = item.externalLink
      this.postData.order = item.order
      this.imageBanner = item.image
      this.imageBannerV2 = item.imageV2
      this.adBanner.image = item.image
      this.adBanner.imageV2 = item.imageV2
      // this.postData.externalLink = item.externalLink
      this.gvExtLink = item.externalLink
      this.postData.typeRedirect = item.typeRedirect
      this.gvType = item.typeRedirect
      // this.postData.order = item.order
      this.postData.cityId = item.cityId
    },
    async newBanner () {
      this.submitOption = 'create'
      // this.dialog = true
      this.postData.title = ''
      this.postData.description = ''
      // this.postData.order = null
      // this.postData.externalLink = ''
      this.imageBanner = null
      this.imageBannerV2 = null
      this.adBanner.image = null
      this.adBanner.imageV2 = null
      this.postData.externalLink = null
      this.postData.typeRedirect = null
      this.postData.order = null
      this.postData.cityId = null
    },
    async editAdImg (item) {
      this.submitOption = 'editImg'
      console.log(item)
      this.dialog = true
      this.postData.id = item.id
      this.imageBanner = item.image
      this.imageBannerV2 = item.imageV2
      this.adBanner.image = item.image
      this.adBanner.imageV2 = item.imageV2
    },
    async deleteBanner (item) {
      // console.log(item)
      this.$swal({
        title: '¿Está seguro de eliminar el banner ' + item.title + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await _adsBannerService.deleteAdBanner(item.id)
            this.$swal(
              'Producto eliminado',
              'Se eliminó correctamente',
              'success'
            ).then(async (result) => {
              this.initialize()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.message + ', no se pudo eliminar.'
            })
          }
        }
      })
    },
    handleBannerUpload (e) {
      // var bannerImg = document.querySelector('#bannerImg')
      // var realWidth = bannerImg.naturalWidth
      // var realHeight = bannerImg.naturalHeight
      // console.log(realHeight, realWidth, bannerImg)
      this.imageBanner = e.target.files
      this.previewBanner(e)
      console.log(this.image)
      if (this.image.height === 924 && this.image.width === 1640) {
        this.enable = true
        this.showError = false
        console.log('permitido')
      } else {
        console.log('no permitido')
        this.enable = false
        this.showError = false
      }
    },
    previewBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          img.onload = () => {
            this.image.width = img.width
            this.image.height = img.height
          }
          img.src = e.target.result
          this.adBanner.image = e.target.result
        }
      }
    },
    handleBannerV2Upload (e) {
      console.log(e)
      // var bannerImg = document.querySelector('#bannerImg')
      // var realWidth = bannerImg.naturalWidth
      // var realHeight = bannerImg.naturalHeight
      // console.log(realHeight, realWidth, bannerImg)
      this.imageBannerV2 = e.target.files
      this.previewBannerV2(e)
      // console.log(this.image)
      // if (this.image.height === 924 && this.image.width === 1640) {
      //   this.enable = true
      //   this.showError = false
      //   console.log('permitido')
      // } else {
      //   console.log('no permitido')
      //   this.enable = false
      //   this.showError = false
      // }
    },
    // previewProfile (event) {
    //   const input = event.target
    //   if (input.files) {
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.barData.bar.images.profile = e.target.result
    //     }
    //     reader.readAsDataURL(input.files[0])
    //   }
    // },
    previewBannerV2 (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          let img = new Image()
          // img.onload = () => {
          //   this.image.width = img.width
          //   this.image.height = img.height
          // }
          console.log(e.target)
          img.src = e.target.result
          this.adBanner.imageV2 = e.target.result
        }
      }
    },
    typeRedir () {
      if (this.submitOption === 'create') {
        this.postData.externalLink = null
      } else if (this.submitOption === 'edit') {
        // this.gvType = this.postData.typeRedirect
        // this.gvExtLink = this.postData.externalLink
        this.postData.externalLink = null
        if (this.gvType === this.postData.typeRedirect) {
          this.postData.externalLink = this.gvExtLink
        }
      }
    },
    async changeCity () {
      this.loading2 = true
      console.log(this.cityBanner)
      // const newBanners = []
      // console.log(newBanners)
      // console.log(this.oldBanners)
      // this.adBanner = this.oldBanners
      // console.log(this.adBanner)
      try {
        const bannerResp = await _adsBannerService.getAdsBannerCity(this.cityBanner)
        console.log(bannerResp)
        this.adsBanner = bannerResp
        this.loading2 = false
      } catch (error) {
        this.loading2 = false
        console.log(error)
      }
      // this.adsBanner.ads = newBanners
      // console.log(newBanners)
    }
  }
}
